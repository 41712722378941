import { Text } from '../../atoms/Text'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { cn } from 'modules/shared'
import { useSessionStorage } from 'shared/hooks'
import { PromotionBannerClosing, useTracking } from 'tracking'

interface PromotionBannerProps {
  id: string
  text: string | React.ReactNode
  isVisible: boolean
  children?: React.ReactNode
  textClassName?: string
  onClick?: () => void
}

const storageKey = (id: string) => ['banner', 'session', id].join('-')

export const PromotionBanner = ({
  id,
  text,
  isVisible,
  children,
  textClassName,
  onClick,
}: PromotionBannerProps) => {
  const [visible, setVisible] = useSessionStorage(storageKey(id), true)

  const { trackEvent } = useTracking()

  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    trackEvent(new PromotionBannerClosing({ id }))
    setVisible(false)
  }

  const shouldShow = visible && isVisible

  const classes = cn(
    'sticky z-[19] left-0',
    'items-center justify-center w-full py-3 pl-12 pr-3',
    'text-center',
    {
      'cursor-pointer': onClick,
    },
    'bg-brand-lighter',
  )

  const Content = () => (
    <>
      {typeof text === 'string' ? (
        <Text size="text-base" weight="font-normal" className={textClassName}>
          {text}
        </Text>
      ) : (
        text
      )}

      <CloseIcon
        className="w-6 h-6 ml-auto cursor-pointer stroke-neutral-darkest"
        onClick={handleClose}
      />
    </>
  )

  return (
    <>
      {Boolean(shouldShow) && (
        <div
          className={cn(
            classes,
            'top-16 hidden md:flex [animation:slide-in-from-top_2s_ease-out,fadeIn_2s]',
          )}
          onClick={onClick}
        >
          <Content />
        </div>
      )}

      {children}

      {Boolean(shouldShow) && (
        <div
          className={cn(classes, 'bottom-0 flex md:hidden animate-slide-in-from-bottom gap-x-2')}
          onClick={onClick}
        >
          <Content />
        </div>
      )}
    </>
  )
}
