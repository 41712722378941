import { FormListing } from 'job-listing/domains'
import {
  ContractLength,
  EngagementType,
  PaymentModel,
  JobListingStackPreferences,
} from 'job-listing/enums'

interface CreateListingRequest {
  title?: string
  roleId?: number
  otherRoleName?: string
  stacks?: Array<{
    stack: {
      id: number
      label?: string
    }
    preference: JobListingStackPreferences
  }>
  location?: string
  numberOfOpenings?: number
  projectDescription?: string
  paymentModel?: PaymentModel
  maximumSalary?: number
  minimumSalary?: number
  maximumHourlyRate?: number
  minimumHourlyRate?: number
  detailedRequirements?: string
  minimumExperience: number
  maximumExperience: number
  engagementType: EngagementType
  contractLength?: ContractLength
  freelancingWorkingHoursPerWeek?: number
  isTalentReview: boolean
}

export class CreateListingDTO {
  constructor(private readonly listing: FormListing) {}

  toJSON(): CreateListingRequest {
    return {
      title: this.listing.jobTitle,
      roleId: this.listing.roleId,
      otherRoleName: this.otherRoleName || undefined,
      numberOfOpenings: this.numberOfOpenings,
      paymentModel: this.listing.paymentModel,
      minimumSalary: this.minimumSalary,
      maximumSalary: this.maximumSalary,
      minimumHourlyRate: this.minimumHourlyRate,
      maximumHourlyRate: this.maximumHourlyRate,
      stacks: this.stacks?.map(({ stack }) => ({
        stack,
        preference: JobListingStackPreferences.Required,
      })),
      detailedRequirements: this.listing.detailedRequirements || undefined,
      projectDescription: this.listing.projectDescription || undefined,
      minimumExperience: this.listing.experienceRange?.min || 0,
      maximumExperience: this.listing.experienceRange?.max || 10,
      engagementType: this.listing.engagementType,
      contractLength: this.isShortEngagement ? this.listing.contractLength! : undefined,
      freelancingWorkingHoursPerWeek: this.isShortEngagement
        ? Number(this.listing.freelancingWorkingHoursPerWeek)
        : undefined,
      isTalentReview: Boolean(this.listing.isTalentReview),
    }
  }

  private get numberOfOpenings() {
    return Number(this.listing.numberOfOpenings || 1)
  }

  private get otherRoleName() {
    return this.listing.roleId ? null : this.listing.roleLabel
  }

  private get minimumSalary() {
    if (this.listing.paymentModel === PaymentModel.Monthly)
      return Number((parseFloat(this.listing.salaryFrom!) * 12).toFixed(2))
  }

  private get maximumSalary() {
    if (this.listing.paymentModel === PaymentModel.Monthly)
      return Number((parseFloat(this.listing.salaryTo!) * 12).toFixed(2))
  }

  private get minimumHourlyRate() {
    if (this.listing.paymentModel === PaymentModel.Hourly) return Number(this.listing.salaryFrom)
  }

  private get maximumHourlyRate() {
    if (this.listing.paymentModel === PaymentModel.Hourly) return Number(this.listing.salaryTo)
  }

  private get stacks() {
    return this.listing.stacks?.map(({ id, label, preference }) => ({
      stack: {
        id,
        label,
      },
      preference,
    }))
  }

  private get isShortEngagement() {
    return this.listing.engagementType === EngagementType.ShortTerm
  }
}
