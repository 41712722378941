import classNames from 'classnames'
import { useFavicon } from 'shared/hooks'
import { ReactComponent as BriefcaseOutlinedIcon } from 'icons/briefcase-02-outlined.svg'
import React from 'react'
import { SensitiveInformation } from 'shared/components/atoms'

interface OrganizationIconProps {
  domain: string | null
  icon?: React.ReactNode
  size?: 'sm' | 'md'
}

export const OrganizationIcon = ({ domain, icon, size = 'md' }: OrganizationIconProps) => {
  const { getUrl } = useFavicon()
  const iconUrl = getUrl(domain, size === 'sm' ? 24 : 16)

  const defaultIcon = (
    <BriefcaseOutlinedIcon
      className={classNames('stroke-neutral-medium', {
        'w-6 h-6': size === 'md',
        'w-4 h-4': size === 'sm',
      })}
    />
  )

  return (
    <SensitiveInformation>
      <div
        className={classNames('relative overflow-hidden rounded-md self-start', {
          'min-h-[48px] min-w-[48px]': size === 'md',
          'min-h-[24px] min-w-[24px]': size === 'sm',
        })}
      >
        {iconUrl ? (
          <>
            <div
              className={classNames(
                'blur-md bg-no-repeat bg-center bg-[length:200%] flex items-center justify-center',
                {
                  'h-12 w-12': size === 'md',
                  'h-6 w-6': size === 'sm',
                },
              )}
              style={{ backgroundImage: `url("${iconUrl}")` }}
            />
            <div
              className={classNames('absolute  rounded flex items-center justify-center', {
                'h-8 w-8 top-2 left-2 bg-white': size === 'md',
                'h-4 w-4 top-1 left-1': size === 'sm',
              })}
            >
              <img src={iconUrl} alt="" />
            </div>
          </>
        ) : (
          <div
            className={classNames('rounded flex items-center justify-center bg-neutral-lightest', {
              'h-12 w-12': size === 'md',
              'h-6 w-6': size === 'sm',
            })}
          >
            {icon || defaultIcon}
          </div>
        )}
      </div>
    </SensitiveInformation>
  )
}
