import './App.css'

import { useEffect, useState, lazy } from 'react'
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { LocalizationResource } from '@clerk/types'

import { JoinWorkspace, LandingPages, SignInPage, SignUpPage } from 'authentication'
import {
  JoinWorkspaceRedirectCallback,
  RedirectCallback,
  ReferralRedirectCallback,
} from 'authentication/exports'
import { PendingOnboardingChecker } from 'onboarding/exports'

import { HomePage } from './pages/HomePage'
import { PendingAgreementChecker } from 'pages/PendingAgreementChecker'
import { SignedInLayout } from 'layouts/SignedInLayout'
import { SignedOutLayout } from 'layouts/SignedOutLayout'
import {
  initialUserState,
  UserContext,
  useLandingTracking,
  useOnboardingParamsTracker,
} from 'shared/hooks'
import { CandidateProfilePage, JobSeekerProfileViewPage } from 'pages/JobSeekerProfile'
import { SensitiveInformationProvider } from 'shared/components/atoms'
import { RouterProvider } from './contexts/router'
import { NavigationTracker, useTracking } from 'tracking'

import { LayoutProvider } from 'contexts/layout'
import { JobSeekerRoutes, EmployerRoutes, EnglishCheckRoutes, ReferRoutes } from 'components/Access'
import { StriderRoutes } from 'components/StriderRoutes.tsx'
import { ChatWidget, HelpProvider } from 'modules/help'
import { CandidateProvider } from 'contexts/candidate'
import { SurveysProvider } from 'modules/surveys'
import { RecruiterRoutes } from 'components/Access/RecruiterRoutes'
import { Redirect } from 'components/molecules/Redirect'
import { RedirectWithQueryParams } from 'components/RedirectWithQueryParams'
import { MonitoringProvider } from 'contexts/monitoring'
import { ScreeningRoutes } from 'components/Access/ScreeningRoutes'
import { JobSeekerHomepage } from 'pages/HomePage/JobSeeker'
import { ErrorBoundary } from 'shared/components/organisms'

const clerkPublishableKey = String(process.env.REACT_APP_CLERK_PUBLISHABLE_KEY)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
})

const AccountDeletionRequested = lazy(() =>
  import('account').then((module) => ({
    default: module.AccountDeletionRequested,
  })),
)

const AccountInfoPage = lazy(() =>
  import('account').then((module) => ({
    default: module.AccountInfo,
  })),
)

const WelcomeBack = lazy(() =>
  import('account').then((module) => ({
    default: module.WelcomeBack,
  })),
)

const Onboarding = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.Onboarding,
  })),
)

const InviteFriends = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.InviteFriends,
  })),
)

const JobSeekerOnboarding = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.JobSeekerOnboarding,
  })),
)

const RecruiterOnboarding = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.RecruiterOnboarding,
  })),
)

const AuthenticatedEmployerOnboarding = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.AuthenticatedEmployerOnboarding,
  })),
)

const DiscoveryCallRequestPage = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.DiscoveryCallRequestPage,
  })),
)

const ApplicationReceived = lazy(() =>
  import('onboarding').then((module) => ({
    default: module.ApplicationReceived,
  })),
)

const CompanySettings = lazy(() =>
  import('company').then((module) => ({
    default: module.CompanySettings,
  })),
)

const CreateListingPage = lazy(() =>
  import('job-listing').then((module) => ({
    default: module.CreateListingPage,
  })),
)

const ReviewListingPage = lazy(() =>
  import('job-listing').then((module) => ({
    default: module.ReviewListingPage,
  })),
)

const JobListingMatchingsPage = lazy(() =>
  import('matching').then((module) => ({
    default: module.JobListingMatchingsPage,
  })),
)

const Screening = lazy(() =>
  import('screening').then((module) => ({
    default: module.Screening,
  })),
)

const Screenings = lazy(() =>
  import('screening').then((module) => ({
    default: module.Screenings,
  })),
)

const CandidateSearchPage = lazy(() =>
  import('candidates-search').then((module) => ({
    default: module.CandidateSearchPage,
  })),
)

const EnglishVetting = lazy(() =>
  import('vetting').then((module) => ({
    default: module.EnglishVetting,
  })),
)

const EvaluationPreviewPage = lazy(() =>
  import('vetting').then((module) => ({
    default: module.EvaluationPreviewPage,
  })),
)

const EvaluationStudioPage = lazy(() =>
  import('vetting').then((module) => ({
    default: module.EvaluationStudioPage,
  })),
)

const EvaluationsPage = lazy(() =>
  import('vetting').then((module) => ({
    default: module.EvaluationsPage,
  })),
)

const Benefits = lazy(() =>
  import('./pages/Benefits').then((module) => ({ default: module.Benefits })),
)

const JobSeekerOffer = lazy(() =>
  import('./pages/Offer/JobSeeker').then((module) => ({ default: module.JobSeekerOffer })),
)

const EmployerOffer = lazy(() =>
  import('./pages/Offer/Employer').then((module) => ({ default: module.EmployerOffer })),
)

const TaxLocation = lazy(() =>
  import('./pages/OutOfBounds').then((module) => ({
    default: module.TaxLocation,
  })),
)

const ManualRejected = lazy(() =>
  import('./pages/OutOfBounds').then((module) => ({
    default: module.ManualRejected,
  })),
)

const Refer = lazy(() =>
  import('./pages/Refer').then((module) => ({
    default: module.Refer,
  })),
)

const ReferralProfilePage = lazy(() =>
  import('./pages/JobSeekerProfile/pages').then((module) => ({
    default: module.ReferralProfilePage,
  })),
)

const JobBoardPage = lazy(() =>
  import('./pages/JobBoard').then((module) => ({
    default: module.JobBoard,
  })),
)

const NotReady = lazy(() =>
  import('./pages/OutOfBounds').then((module) => ({
    default: module.NotReady,
  })),
)

const DoNotOperate = lazy(() =>
  import('./pages/OutOfBounds').then((module) => ({
    default: module.DoNotOperate,
  })),
)

const UnsupportedRole = lazy(() =>
  import('./pages/OutOfBounds').then((module) => ({
    default: module.UnsupportedRole,
  })),
)

const LinkExpired = lazy(() =>
  import('./pages/OutOfBounds').then((module) => ({
    default: module.LinkExpired,
  })),
)

const EditJobSeekerProfilePage = lazy(() =>
  import('./pages/JobSeekerProfile').then((module) => ({
    default: module.EditJobSeekerProfilePage,
  })),
)

const ReferralsPage = lazy(() =>
  import('./pages/Referrals').then((module) => ({ default: module.Referrals })),
)

const OfferSentPage = lazy(() =>
  import('./pages/Offer/OfferSentPage').then((module) => ({
    default: module.OfferSentPage,
  })),
)

const localization: LocalizationResource = {
  formButtonPrimary: 'Continue with email',
  signIn: {
    start: {
      title: 'Log in',
      actionText: "Don't have an account?",
      actionLink: 'Create account',
    },
  },
  signUp: {
    start: {
      title: 'Create your account',
      actionText: 'Already have an account?',
    },
  },
}

function App() {
  useLandingTracking()
  useOnboardingParamsTracker()

  const navigate = useNavigate()
  const { identifyUser } = useTracking()
  const [user, setUser] = useState(initialUserState.user)
  const [isLoaded, setIsLoaded] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const userContext = { user, setUser, isLoaded, setIsLoaded, notFound, setNotFound }

  useEffect(() => {
    identifyUser(user)
  }, [user.accountId])

  return (
    <ErrorBoundary>
      <MonitoringProvider>
        <RouterProvider>
          <SensitiveInformationProvider>
            <QueryClientProvider client={queryClient}>
              <ClerkProvider
                publishableKey={clerkPublishableKey}
                navigate={navigate}
                localization={localization}
              >
                <LayoutProvider>
                  <HelpProvider>
                    <NavigationTracker />
                    <SignedIn>
                      <UserContext.Provider value={userContext}>
                        <SurveysProvider>
                          <PendingAgreementChecker />
                          <PendingOnboardingChecker />
                          <ChatWidget />
                          <CandidateProvider>
                            <Routes>
                              <Route element={<SignedInLayout />}>
                                <Route element={<StriderRoutes />}>
                                  <Route
                                    path="/candidates/:id/edit"
                                    element={<EditJobSeekerProfilePage />}
                                  />
                                  <Route
                                    path="/candidates/search"
                                    element={<CandidateSearchPage />}
                                  />
                                  <Route
                                    path="/candidates/search/new"
                                    element={<CandidateSearchPage />}
                                  />
                                  <Route
                                    path="/candidates/search/:searchId"
                                    element={<CandidateSearchPage />}
                                  />
                                </Route>

                                <Route element={<JobSeekerRoutes />}>
                                  <Route path="/profile" element={<JobSeekerProfileViewPage />} />
                                  <Route
                                    path="/profile/pay-rate/edit"
                                    element={<JobSeekerProfileViewPage />}
                                  />
                                  <Route path="/english-vetting" element={<EnglishVetting />} />
                                  <Route path="/offers/:offerId" element={<JobSeekerOffer />} />
                                  <Route
                                    path="/job-opportunities/:jobOpportunityId"
                                    element={<JobSeekerHomepage isJobOpportunity />}
                                  />
                                  <Route path="/account-settings" element={<AccountInfoPage />} />
                                </Route>

                                <Route element={<EnglishCheckRoutes />}>
                                  <Route
                                    path="/english-check/evaluations"
                                    element={<EvaluationsPage />}
                                  />
                                  <Route
                                    path="/english-check/evaluations/:evaluationId"
                                    element={<EvaluationStudioPage />}
                                  />
                                  <Route
                                    path="/english-check/evaluations/:evaluationId/watch"
                                    element={<EvaluationPreviewPage />}
                                  />
                                </Route>

                                <Route path="/benefits" element={<Benefits />} />

                                <Route element={<EmployerRoutes />}>
                                  <Route path="/settings" element={<CompanySettings />} />
                                  <Route path="/listings/create" element={<CreateListingPage />}>
                                    <Route path=":listingId" element={<CreateListingPage />} />
                                  </Route>
                                  <Route
                                    path="/listings/:listingId/matchings"
                                    element={<JobListingMatchingsPage />}
                                  />
                                  <Route
                                    path="/listings/:listingId/matchings/:matchingId"
                                    element={<JobListingMatchingsPage />}
                                  />
                                  {/* @ todo - find a way to use navigate. It is not sending the listing Id to the new route */}
                                  <Route
                                    path="/listings/:listingId"
                                    element={<JobListingMatchingsPage />}
                                  />
                                  <Route
                                    path="/listings/:listingId/review"
                                    element={<ReviewListingPage />}
                                  />
                                  <Route
                                    path="/candidates/:id"
                                    element={<CandidateProfilePage />}
                                  />
                                  <Route path="/offers/:offerId/sign" element={<EmployerOffer />} />
                                  <Route element={<ScreeningRoutes />}>
                                    <Route
                                      path="/listings/:listingId/screenings"
                                      element={<Screenings />}
                                    />
                                    <Route
                                      path="/listings/:listingId/screenings/create"
                                      element={<Screening />}
                                    />
                                    <Route
                                      path="/listings/:listingId/screenings/:screeningId"
                                      element={<Screening />}
                                    />
                                  </Route>
                                </Route>
                                <Route path="/referrals" element={<ReferralsPage />} />
                                <Route path="/offer-sent" element={<OfferSentPage />} />

                                <Route element={<ReferRoutes />}>
                                  <Route path="/refer" element={<Refer />} />
                                  <Route
                                    path="/refer/fees"
                                    element={
                                      <Redirect url="https://help.onstrider.com/en/articles/6986019-recruiter-fees-payment-details" />
                                    }
                                  />
                                  <Route
                                    path="/refer/supported-countries"
                                    element={
                                      <Redirect url="https://help.onstrider.com/en/articles/7203294-supported-countries" />
                                    }
                                  />
                                </Route>

                                <Route element={<RecruiterRoutes />}>
                                  <Route path="/job-board" element={<JobBoardPage />} />
                                  <Route
                                    path="/referrals/:id/profile"
                                    element={<ReferralProfilePage />}
                                  />
                                </Route>

                                <Route path="/" element={<HomePage />} />
                                <Route path="/onboarding" element={<Onboarding />} />

                                <Route
                                  path="/job-seeker-onboarding"
                                  element={<JobSeekerOnboarding />}
                                />
                                <Route
                                  path="/employer-onboarding"
                                  element={
                                    <AuthenticatedEmployerOnboarding isUserLoaded={isLoaded} />
                                  }
                                />
                                <Route
                                  path="/recruiter-onboarding"
                                  element={<RecruiterOnboarding />}
                                />
                                <Route path="/invite-friends" element={<InviteFriends />} />

                                {/* welcome prefix is required to enforce correct onboarding validation (PendingOnboardingChecker) */}
                                <Route path="/welcome-unsupported" element={<UnsupportedRole />} />
                                <Route path="/welcome-out-of-bounds" element={<DoNotOperate />} />
                                <Route
                                  path="/welcome-application-received"
                                  element={<ApplicationReceived />}
                                />
                                <Route
                                  path="/welcome-payment-out-of-bounds"
                                  element={<TaxLocation />}
                                />
                                <Route
                                  path="/welcome-application-rejected"
                                  element={<ManualRejected />}
                                />
                                <Route path="/welcome-not-ready" element={<NotReady />} />

                                {/* Needed to make the redirect url work properly */}
                                <Route
                                  path="/r/:referrerSlug"
                                  element={<ReferralRedirectCallback />}
                                />
                                <Route
                                  path="/refer/:referrerSlug"
                                  element={<ReferralRedirectCallback />}
                                />
                                <Route path="/signup" element={<RedirectCallback />} />
                                <Route path="/join" element={<RedirectCallback />} />
                                <Route
                                  path="/join-workspace/:id"
                                  element={<JoinWorkspaceRedirectCallback />}
                                />
                                <Route path="/welcome-back" element={<WelcomeBack />} />

                                <Route path="*" element={<RedirectWithQueryParams to="/" />} />
                              </Route>
                            </Routes>
                          </CandidateProvider>
                        </SurveysProvider>
                      </UserContext.Provider>
                    </SignedIn>
                    <SignedOut>
                      <Routes>
                        <Route path="/login" element={<SignInPage />} />
                        <Route path="/sign-in" element={<RedirectWithQueryParams to="/login" />} />

                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/sign-up" element={<RedirectWithQueryParams to="/signup" />} />

                        <Route path="/join-workspace/:id" element={<JoinWorkspace />} />
                        <Route path="/join" element={<LandingPages />} />
                        <Route
                          path="/r/:referrerSlug"
                          element={<ReferralRedirectCallback isSignUp />}
                        />
                        <Route
                          path="/refer/:referrerSlug"
                          element={<ReferralRedirectCallback isSignUp />}
                        />

                        <Route element={<SignedOutLayout />}>
                          <Route
                            path="/employer-onboarding"
                            element={<DiscoveryCallRequestPage />}
                          />
                          <Route path="/hire-developers" element={<DiscoveryCallRequestPage />} />
                          <Route
                            path="/deletion-requested"
                            element={<AccountDeletionRequested />}
                          />
                          <Route path="/welcome-link-expired" element={<LinkExpired />} />
                        </Route>

                        <Route path="*" element={<RedirectWithQueryParams to="/login" />} />
                      </Routes>
                    </SignedOut>
                  </HelpProvider>
                </LayoutProvider>
              </ClerkProvider>
            </QueryClientProvider>
          </SensitiveInformationProvider>
        </RouterProvider>
      </MonitoringProvider>
    </ErrorBoundary>
  )
}

export default App
