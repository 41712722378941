import { PrimaryButton, SecondaryButton, TertiaryButton } from 'shared/components/atoms'
import { Modal } from 'shared/components/molecules'
import { useImportProfile } from './hooks/useImportProfile'
import { FormEvent, useState } from 'react'
import { ImportForm } from './components/ImportForm'
import { ExperiencesReviewer } from './components/ExperiencesReviewer'
import { useQueryClient } from 'react-query'

interface ImportingModalProps {
  isOpen: boolean
  onClose: () => void
  profileId: string
  onAddWorkExperienceClick?: () => void
}

export const ImportingModal = ({
  profileId,
  isOpen,
  onClose,
  onAddWorkExperienceClick,
}: ImportingModalProps) => {
  const client = useQueryClient()
  const { importProfile, isImporting } = useImportProfile(profileId)
  const [error, setError] = useState('')
  const [imported, setImported] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
  }

  const handleFileChosen = (file: File | null) => {
    setFile(file)
    handleImport(file)
  }

  const handleImport = (file: File | null) => {
    setUploaded(false)

    if (!file) return

    setError('')

    importProfile(file)
      .then(() => {
        setUploaded(true)
        setImported(true)
        client.invalidateQueries('profile')
        client.invalidateQueries(`candidates/${profileId}`)
      })
      .catch((error: RequestError) => {
        const defaultMessage =
          'We could not parse the uploaded file. You can still fill in the data manually.'

        if (error.response?.status === 415) {
          return setError(error.response?.data.message || defaultMessage)
        }

        if (error.response?.status === 422) {
          return setError(
            'The uploaded file was not a recognizable LinkedIn profile with work experience content. You can still fill in the data manually or try uploading a valid file.',
          )
        }

        setError(defaultMessage)
      })
  }

  const handleClose = () => {
    setFile(null)
    setImported(false)
    setUploaded(false)
    setError('')
    onClose()
  }

  const handleNext = () => {
    if (uploaded) {
      return setImported(true)
    }

    setError('Upload a LinkedIn PDF to continue')
  }

  return (
    <Modal
      title="Import work experiences"
      open={isOpen}
      handleClose={handleClose}
      contentContainerClassName="sm:pt-6 sm:!pb-3"
      content={
        <>
          {imported ? (
            <ExperiencesReviewer profileId={profileId} onClose={handleClose} />
          ) : (
            <ImportForm
              file={file}
              onSubmit={handleSubmit}
              setFile={handleFileChosen}
              error={error}
            />
          )}
        </>
      }
      footer={
        <>
          <TertiaryButton onClick={handleClose} className="w-full md:w-fit">
            Cancel
          </TertiaryButton>
          {Boolean(onAddWorkExperienceClick) && (
            <SecondaryButton onClick={onAddWorkExperienceClick} className="w-full md:w-fit">
              Add manually
            </SecondaryButton>
          )}
          <PrimaryButton
            isLoading={isImporting}
            form="importing-upload"
            onClick={handleNext}
            className="w-full md:w-fit"
          >
            Next
          </PrimaryButton>
        </>
      }
    />
  )
}
