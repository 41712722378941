import { Paragraph, Picture, Text } from 'shared/components/atoms'

import { ProfileFulfillmentBadge, ProfileStatusBadge } from 'components/molecules/Badges'

import { Candidate } from 'candidates-search/domain'
import { cn } from 'modules/shared'
import { useDate } from 'shared/hooks'

interface ProfileHeaderProps {
  candidate: Candidate
  containerClassName?: string
  minimal?: boolean
}

export const ProfileHeader = ({ candidate, containerClassName, minimal }: ProfileHeaderProps) => {
  const { getDifferenceInDays } = useDate()
  const lastExperience = candidate.workExperiences[0]
  const pausedDays = candidate.availableFrom
    ? getDifferenceInDays(new Date(candidate.availableFrom))
    : undefined

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-4',
        { 'flex-row justify-between w-full': minimal },
        containerClassName,
      )}
    >
      <div
        className={cn('flex flex-col items-center gap-2', {
          'flex-row gap-4 items-center': minimal,
        })}
      >
        <div className="flex items-end">
          <Picture
            photoUrl={candidate.photoUrl}
            size={minimal ? 'lg' : 'xl'}
            className="transition-all duration-300"
          />
          <div className="-ml-6">
            <ProfileFulfillmentBadge status={candidate.fulfillmentStatus} />
          </div>
        </div>
        <div className={cn('flex flex-col items-center', { 'items-start': minimal })}>
          <Text
            size={minimal ? 'text-base' : 'text-xl'}
            weight="font-normal"
            className="transition-all duration-300"
          >
            {candidate.fullName}
          </Text>
          <Paragraph size="body-sm" className="text-neutral-dark">
            {lastExperience ? lastExperience.title : 'No experience'}
          </Paragraph>
        </div>
      </div>

      <ProfileStatusBadge
        status={candidate.availabilityStatus}
        days={pausedDays}
        className="print:hidden"
      />
    </div>
  )
}
