import classNames from 'classnames'

import { HideScrollContainer, PreviewLabel } from 'shared/components/atoms'
import { ProfileStatusBadge } from 'components/molecules/Badges'
import { EmployerActions } from 'components/organisms/EmployerActions'
import { EnglishLevelBadge } from 'vetting/exports'
import { useDate } from 'shared/hooks'
import { ProfileOverview } from 'pages/JobSeekerProfile/types'

import { AssessmentsCollection } from '../AssessmentsCollection'
import { Heading } from './components/Heading'
import { LinksCollection } from './components/LinksCollection'
import { PublicPreferences } from './components/PublicPreferences'
import { MockInterview } from './components/MockInterview'

interface CandidateSidebarProps {
  profile: ProfileOverview
  onPrint: (fileName: string) => void
}

export const CandidateSidebar = ({ profile, onPrint }: CandidateSidebarProps) => {
  const { getDifferenceInDays } = useDate()
  const days = getDifferenceInDays(profile.preferences?.pausedUntil ?? undefined)

  return (
    <HideScrollContainer
      className={classNames(
        'flex flex-col gap-12',
        'h-full lg:h-screen lg:w-[264px]',
        'static lg:sticky lg:top-0 ',
        'lg:pt-[152px] lg:-mt-[152px] lg:pb-20 lg:-mb-20',
        'lg:overflow-y-scroll',
      )}
    >
      <div className="flex flex-col gap-6 w-[264px]">
        <Heading profile={profile} readOnly onEdit={() => {}} />

        {!profile.offerSent && (
          <ProfileStatusBadge
            status={profile.preferences?.status}
            days={days}
            withBackground
            className="w-fit h-8"
          />
        )}

        <div className="flex w-full">
          <EmployerActions isVisible profile={profile} onExport={onPrint} isStandAloneProfile />
        </div>
      </div>

      <PublicPreferences profile={profile} />

      <LinksCollection profile={profile} title="Links" />

      {profile.englishVetting && (
        <PreviewLabel title="English level" disabled className="print:hidden">
          <EnglishLevelBadge
            englishLevel={profile.englishVetting}
            withIcon={false}
            textSize="text-sm"
          />
        </PreviewLabel>
      )}

      <MockInterview mockInterviewDone={profile.isMockInterviewDone} />

      <AssessmentsCollection profile={profile} />
    </HideScrollContainer>
  )
}
