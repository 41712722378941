import { Company } from 'company/domain'
import { ContractModes, ListingStatus, JobListingStackPreferences } from 'job-listing/enums'
import { PositionsSlug } from 'modules/shared/enums'
import { Type } from 'class-transformer'
import { StackExperience } from 'pages/JobSeekerProfile/types'

export class Stack {
  id: number
  label: string
}

export class Role {
  id: number
  label: string
  roleType: PositionsSlug
}

export class ListingStack {
  preference: JobListingStackPreferences
  stack: Stack
}

export class ListingTerm {
  upfrontFee: number
  successFee: number
  guaranteeDays: number
}

export class Subscription {
  id: string
  subscriberId: number
  email: string
}

export class ListingInfo {
  id: string
  title: string
  otherRoleName?: string
  minimumSalary?: number
  maximumSalary?: number
  numberOfOpenings: number
  status: ListingStatus
  projectDescription: string
  detailedRequirements?: string
  minimumExperience?: number
  maximumExperience?: number
  contractMode?: ContractModes
  experienceRangeLabel: string
  budgetLabel: string
  contractDetailsLabel: string
  feeLabel?: string

  roleLabel: string
  stacks?: Array<ListingStack>
  listingTerm: ListingTerm
  internalNotes?: string
  matchingsChecklist?: string
  subscriptions: Array<Subscription>

  jobOffersEnabled: boolean
  hiringCostsVisible: boolean
  hourlyRatesEnabled: boolean
  hasHourlyMatchings: boolean
  hasMonthlyMatchings: boolean
  isTalentReview: boolean
  externalId: string

  @Type(() => Company)
  company: Company

  isOwner(companyId: number | undefined) {
    return companyId === this.company.id
  }

  getCandidateMatchingSkills(candidateSkills: Array<StackExperience>) {
    return candidateSkills
      .map((skill) => ({
        ...skill,
        highlighted:
          this.stacks?.some((desiredSkill) => desiredSkill.stack.id === skill.stack.id) || false,
      }))
      .sort((a, b) => (a.highlighted ? -1 : 1))
  }
}
