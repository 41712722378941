import { ClaimProtected, PreviewLabel, Text } from 'shared/components/atoms'
import { AccountClaim } from 'shared/hooks'

interface MockInterviewProps {
  mockInterviewDone: boolean
}
export const MockInterview = ({ mockInterviewDone }: MockInterviewProps) => {
  if (!mockInterviewDone) return null

  return (
    <ClaimProtected require={AccountClaim.Staff}>
      <PreviewLabel title="Mock Interview" disabled className="print:hidden">
        <Text size="text-sm" className="text-success-medium flex">
          Done
        </Text>
      </PreviewLabel>
    </ClaimProtected>
  )
}
